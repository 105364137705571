import React, { useEffect, useState } from "react";
import { Grid, Box, Stack, Typography, Button, FormControl, InputLabel, Snackbar, Select, MenuItem, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { ContentBoxWrapper } from "../../styles/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import PropTypes from 'prop-types';
import moment from "moment";
import dayjs from "dayjs";
import { Authenticated, GetAuthUserName } from "../../components/AuthenticatedComponent";
import MuiAlert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';

import { TimeCell } from "../../components/TimeSheet/TimeCell";
import TimeTable from "../../components/TimeSheet/TimeTable";
import PTOReqeustTable from "../../components/TimeSheet/PTORequestTable";
import TimeSheetDetails from "../../components/TimeSheet/TimeSheetDetails";
import PTORequestForm from "../../components/TimeSheet/PTORequestForm";
import PTODetailsTable from "../../components/TimeSheet/PTODetailsTable";
import HolidayInputForm from "../../components/TimeSheet/HolidayInputForm";
import HolidayTable from "../../components/TimeSheet/HolidayTable";

const borderStyle = {
    top: 0,
    bottom: 0,
    right: 0,
    width: "1px",
    backgroundColor: 'grey',
    margin: "0",
    height: "100%",
};

function getCurrentWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 1
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 1));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return week number
    return weekNo;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createEmployeeData(userid, username, firstname, lastname, email, role, department, managerid, totalpto, pendingpto, usedpto, rollover, rolloverdate, remainingpto) {
    return {
        userid,
        username,
        firstname,
        lastname,
        email,
        role,
        department,
        managerid,
        totalpto,
        pendingpto,
        usedpto,
        rollover,
        rolloverdate,
        remainingpto
    }
}

function createWeeklyData(weeknumber, userid, year, username, firstname, lastname, email, role, department, startdate, standardhours, overtime, pto, holidayhours, vacationhours, sickhours, otherhours, totalhours, status, currenttimelog, declinenote) {
    return {
        weeknumber,
        userid,
        year,
        username,
        firstname,
        lastname,
        email,
        role,
        department,
        startdate,
        standardhours,
        overtime,
        pto,
        holidayhours,
        vacationhours,
        sickhours,
        otherhours,
        totalhours,
        status,
        currenttimelog,
        declinenote
    }
}

function createTimeLogData(timesheetid, userid, date, clockin, clockout, hourtype, hoursworked, day, description, weekof) {
    return {
        timesheetid,
        userid,
        date,
        clockin,
        clockout,
        hourtype,
        hoursworked,
        day,
        description,
        weekof
    }
}

const weekDays = [
    { day: "Sunday" },
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
];

let totalHours = {
    "Regular Work": 0,
    "Overtime": 0,
    "PTO": 0,
    "Holiday": 0,
    "Other": 0,
    "Total": 0
}

export function TimeSheet() {

    let location = useLocation();
    const [currentWeek, setCurrentWeek] = useState(getWeekRange(new Date()).from);
    const [currentWeekNumber, setCurrentWeekNumber] = useState(getCurrentWeekNumber(new Date()));
    const [year, setYear] = useState(new Date().getFullYear());
    const [status, setStatus] = useState("Not Submitted");
    const [worker, setWorker] = useState("");
    const [userId, setUserId] = useState("");
    const [inProgTimeLog, setInProgTimeLog] = useState(null);
    const [subordinateIdsList, setSubordinateIdsList] = useState([]);
    const [tabValue, setTabValue] = new useState(0);
    const [clockInTime, setClockInTime] = useState(null);
    const [clockOutTime, setClockOutTime] = useState(null);
    const [timeSheetListener, setTimeSheetListener] = useState(0);
    const [ptoRequestListener, setPtoRequestListener] = useState(0);
    const [holidayListener, setHolidayListener] = useState(0);
    const [approveSubmission, setApproveSubmission] = useState(false);
    const [submitForApproval, setSubmitForApproval] = useState(false);
    const [revokeSubmission, setRevokeSubmission] = useState(false);
    const [openPTOModal, setOpenPTOModal] = useState(false);
    const [openHolidayModal, setOpenHolidayModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");
    const [confirmAction, setConfirmAction] = useState(null);
    const [approved, setApproved] = useState(false);
    const [declineReason, setDeclineReason] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [totalPto, setTotalPto] = useState(0);
    const [remainingPto, setRemainingPto] = useState(0);
    const [pendingPto, setPendingPto] = useState(0);
    const [usedPto, setUsedPto] = useState(0);
    const [rollOverPto, setRollOverPto] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [weeklyList, setWeeklyList] = useState([]);
    const [timeLogsList, setTimeLogsList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [weekTotalHours, setWeekTotalHours] = useState(totalHours);
    const [timesArray, setTimesArray] = useState([
        {
            day: "Sunday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Monday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Tuesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Wednesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Thursday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Friday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Saturday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
    ]);

    const managerRole = ["Service_TimeSheet_Manager", "SI_TimeSheet_Manager"];
    const employeeRole = ["Service_TimeSheet_User", "SI_TimeSheet_User"];
    const payrollRole = ["Payroll_Processors"];
    const adminRole = ["Admin"];
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const hourtypes = ["Regular Work", "Overtime", "PTO", "Holiday", "Other"]

    const today = new Date();

    const fetchEmployeeData = () => {
        setLoading(true);
        const endpoint = 'api/Employee/employee';
        axios.get(endpoint)
            .then((response) => {
                loadEmployeeList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchWeeklyData = () => {
        setLoading(true);
        const endpoint = 'api/Timesheet/timeSheetWeekly';
        axios.get(endpoint)
            .then((response) => {
                loadWeeklyList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchWeeklyData();
        // eslint-disable-next-line
    }, [submitted]);

    const fetchTimeSheetData = () => {
        setLoading(true);
        const endpoint = 'api/Timesheet/timeSheet';
        axios.get(endpoint)
            .then((response) => {
                loadTimeLogList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchEmployeeData();
        fetchTimeSheetData();
        // eslint-disable-next-line
    }, []);

    const handleTransferData = (log) => {
        if (new Date(log.date) >= currentWeek && new Date(log.date) <= getWeekRange(new Date(currentWeek)).to && log.userid === worker) {
            timeLogArray[weekdays.indexOf(log.day)].hours[hourtypes.indexOf(log.hourtype)].time += log.hoursworked;
            timeLogArray[weekdays.indexOf(log.day)].hours[5].time += log.hoursworked;
            totalHours[log.hourtype] += Number(log.hoursworked.toFixed(2));
        }
    }

    const handleUpdatePtoRequests = () => {
        setPtoRequestListener(ptoRequestListener + 1);
    }

    const handleUpdateHolidays = () => {
        setHolidayListener(holidayListener + 1);
    }

    const handleUpdateTimeSheetCells = () => {
        totalHours = {
            "Regular Work": 0,
            "Overtime": 0,
            "PTO": 0,
            "Holiday": 0,
            "Other": 0,
            "Total": 0
        }
        timeLogsList.map((log) => (
            handleTransferData(log)
        ));
        totalHours["Total"] = totalHours["Regular Work"] + totalHours["Overtime"] + totalHours["PTO"] + totalHours["Holiday"] + totalHours["Other"];
        setWeekTotalHours(totalHours);
        setTimesArray(timeLogArray);
    }

    const handleSetInProgTimeLog = () => {
        const inProg = timeLogsList.find((log) => 
            (log.userid === worker && new Date(log.weekof).getTime() === offsetDate(currentWeek).getTime() && log.date.getDay() === today.getDay() && log.clockin !== null && log.clockout === null));
        inProg ? setInProgTimeLog(inProg.timesheetid) : setInProgTimeLog(null);
    }

    const handleSetPtoDisplay = () => {
        employeeList.forEach(e => {
            if (worker === e.userid) {
                setTotalPto(e.totalpto ? e.totalpto : 0);
                setRemainingPto(e.remainingpto ? e.remainingpto : 0);
                setPendingPto(e.pendingpto ? e.pendingpto : 0);
                setUsedPto(e.usedpto ? e.usedpto : 0);
                setRollOverPto(e.rollover ? e.rollover : 0);
            }
        })
    }

    useEffect(() => {
        const inProg = timeLogsList.find((log) => (log.timesheetid === inProgTimeLog));
        inProg ? setClockInTime(dayjs(inProg.date.toISOString().slice(0, 11) + inProg.clockin + ".000Z")) : setClockInTime(null);
        // eslint-disable-next-line
    }, [inProgTimeLog])

    useEffect(() => {
        handleUpdateTimeSheetCells();
        handleSetInProgTimeLog();
        handleSetPtoDisplay();
        // eslint-disable-next-line
    }, [currentWeek, worker, timeLogsList])

    useEffect(() => {
        handleSetPtoDisplay();
        // eslint-disable-next-line
    }, [employeeList])

    const loadEmployeeList = (employees) => {

        let newRows = [];
        let id = 0;
        let subordinateList = [];
        if (employees.length > 0) {
            employees.forEach(e => {
                if (GetAuthUserName() === e.userName) {
                    setUserId(e.userId);
                    setWorker(e.userId);
                    id = e.userId;
                }
            })
            employees.forEach(e => {
                if (id === e.userId || id === e.managerId || Authenticated(payrollRole)) {
                    newRows.push(createEmployeeData(
                        e.userId,
                        e.userName,
                        e.firstName,
                        e.lastName,
                        e.email,
                        e.role,
                        e.department,
                        e.managerId,
                        e.ptoTotal,
                        e.ptoPending,
                        e.ptoUsed,
                        e.rollOverPto,
                        e.rollOverExpirationDate,
                        e.ptoRemaining
                    ))
                }
                if (id === e.managerId || (Authenticated(payrollRole) && id !== e.userId)) {
                    subordinateList.push(e.userId);
                }
            })
        }
        setEmployeeList(newRows);
        setSubordinateIdsList(subordinateList);
    }

    const loadWeeklyList = (timeSheets) => {

        let newRows = [];
        if (timeSheets.length > 0) {
            timeSheets.forEach(ts => {
                const offsetDate = (date) => {
                    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
                }
                newRows.push(createWeeklyData(
                    ts.weekNumber,
                    ts.empUserId,
                    ts.tsYear,
                    ts.userName,
                    ts.firstName,
                    ts.lastName,
                    ts.email,
                    ts.role,
                    ts.department,
                    ts.weekStartDate ? offsetDate(new Date(ts.weekStartDate)) : null,
                    ts.regularHours,
                    ts.overtimeHours,
                    ts.ptoHours,
                    ts.holidayHours,
                    ts.vacationHours,
                    ts.sickHours,
                    ts.otherHours,
                    ts.totalHoursWorked,
                    ts.tsStatus,
                    ts.currentTsId,
                    ts.declineNote
                ))
                
            })
        }
        setWeeklyList(newRows);
    }

    const loadTimeLogList = (timeLogs) => {
        let newRows = [];
        if (timeLogs.length > 0) {
            timeLogs.forEach(log => {
                const offsetDate = (date) => {
                    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
                }
                newRows.push(createTimeLogData(
                    log.timesheetId,
                    log.empUserId,
                    log.timesheetDate ? offsetDate(new Date(log.timesheetDate)) : null,
                    log.clockIn,
                    log.clockOut,
                    log.hourType,
                    log.hoursWorked,
                    log.tsDay,
                    log.tsDescription,
                    log.weekOf,
                ))
            })
        }
        setTimeLogsList(newRows);
    }

    const handleWeeklyAttributes = (startDate, weekNumber, year, status) => {
        setCurrentWeek(startDate);
        setCurrentWeekNumber(weekNumber);
        setYear(year);
        setStatus(status);
    }

    const handleTimeSheetSelect = (week, employeeId, year) => { //Set the employee, weeknumber, current week, year, and status
        employeeList.map((e) => (
            e.userid === employeeId ? setWorker(e.userid) : null
        ))
        weeklyList.map((ts) => (
            ts.weeknumber === week & ts.userid === employeeId & ts.year === year ? handleWeeklyAttributes(ts.startdate, ts.weeknumber, ts.year, ts.status) : null
        ))
    }

    const handleSetArrayTimes = (val, weekday, type, description = null) => {
        timeLogArray[weekdays.indexOf(weekday)].hours[hourtypes.indexOf(type)].time = val;
    }

    let timeLogArray = [
        {
            day: "Sunday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Monday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Tuesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Wednesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Thursday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Friday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Saturday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
    ];


    useEffect(() => {
        setClockOutTime(null);
    }, [clockInTime]);

    useEffect(() => {
        if (clockInTime || clockOutTime) {
            handleSaveCurrentWeek();
        }
        // eslint-disable-next-line
    }, [clockInTime, clockOutTime])

    useEffect(() => {
        if (status === "Pending Approval") {
            setApproveSubmission(false);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            setApproved(false);
            setSubmitted(true);
        }
        if (status === "Not Submitted") {
            setApproveSubmission(false);
            setSubmitForApproval(false);
            setRevokeSubmission(true);
            setSubmitted(false);
            setApproved(false);
        }
        if (status === "Approved") {
            setApproveSubmission(true);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            setApproved(true);
            setSubmitted(true);
        }
    }, [currentWeek, worker, status])

    const handleClockInNow = (event) => {
        const now = new Date();
        setClockInTime(dayjs(now.getTime()));
    }

    const handleClockOutNow = (event) => {
        const now = new Date();
        setClockOutTime(dayjs(now.getTime()));
    }

    const handleWorkerSelect = (event) => {
        setWorker(event.target.value);
    }

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 1
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 1));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return week number
        return weekNo;
    }

    const handleDateChange = (val) => {
        var d = getWeekRange(new Date(val)).from;
        setCurrentWeek(d);
        setCurrentWeekNumber(getWeekNumber(d));
        setYear(currentWeek.getFullYear());
        setStatus("Not Submitted");
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
    }

    const handleUpdateTimeTable = () => {

        setLoading(true);

        const employee = employeeList.find(o => o.userid === worker);
        if (employee) {
            const newWeeklyTimesheet = {
                weekNumber: getWeekNumber(currentWeek),
                empUserId: worker,
                tsYear: currentWeek.getFullYear(),
                userName: employee.username,
                firstName: employee.firstname,
                lastName: employee.lastname,
                email: employee.email,
                role: employee.role,
                department: employee.department,
                weekStartDate: currentWeek.toISOString().substring(0, 10),
                weekEndDate: offsetDate(getWeekRange(currentWeek).to).toISOString().substring(0, 10),
                regularHours: totalHours["Regular Work"],
                overtimeHours: totalHours["Overtime"],
                ptoHours: totalHours["PTO"],
                holidayHours: totalHours["Holiday"],
                vacationHours: 0,
                sickHours: 0,
                otherHours: totalHours["Other"],
                totalHoursWorked: totalHours["Total"],
                tsStatus: "Not Submitted",
                currentTsId: inProgTimeLog,
            }

            axios
                .put("/api/TimeSheet/updateTimeSheetWeekly", newWeeklyTimesheet)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error creating Timesheet. Please try again.");
                })
                .finally(() => {
                    setTimeSheetListener(timeSheetListener + 1);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        handleUpdateTimeTable();
        // eslint-disable-next-line
    }, [weekTotalHours])

    const handleSaveCurrentWeek = () => {
        setLoading(true);

        const handleCleanUp = () => {
            if (clockInTime && clockOutTime) {
                setClockInTime(null);
                setClockOutTime(null);
            }
        }

        const handleCalcHours = () => {
            let timeAdjustment = 0;
            if (clockInTime && clockOutTime) {
                if (((clockOutTime - clockInTime) / 3600000) >= 6) {
                    timeAdjustment -= 0.5;
                }
                return (((clockOutTime - clockInTime) / 3600000 + timeAdjustment).toFixed(2));
            }
            return (0);
        }

        const getNextDateFromDay = () => {
            const i = weekdays.indexOf(weekDays[new Date().getDay()].day);
            var weekStart = new Date(currentWeek);
            if (weekDays[new Date().getDay()].day === "Saturday") {
                weekStart = new Date(currentWeek.getFullYear(), currentWeek.getMonth(), currentWeek.getDate()+7)
            }
            return moment(weekStart).add(i + 1, 'days')
        }

        if (currentWeek.getTime() === getWeekRange(today).from.getTime()) {

            let tempTimeLog = {};

            if (handleCalcHours() >= 0) {
                if (inProgTimeLog === null) {
                    tempTimeLog = {
                        empUserId: worker,
                        timesheetDate: new Date().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                        hourType: "Regular Work",
                        hoursWorked: handleCalcHours(),
                        tsDescription: "-",
                        tsDay: weekDays[new Date().getDay()].day,
                        weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                    };
                }
                else {
                    tempTimeLog = {
                        timesheetId: inProgTimeLog,
                        empUserId: worker,
                        timesheetDate: new Date().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                        hourType: "Regular Work",
                        hoursWorked: handleCalcHours(),
                        tsDescription: "-",
                        tsDay: weekDays[new Date().getDay()].day,
                        weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                    };
                }
    
                const newTimeLog = tempTimeLog;
    
                axios
                    .put("/api/TimeSheet/updateTimeLog", newTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        fetchTimeSheetData();
                        handleUpdateTimeTable();
                        setTimeSheetListener(timeSheetListener + 1);
                        if (!(inProgTimeLog && handleCalcHours() === 0)) {handleSaveDialog();}
                        handleCleanUp();
                        setLoading(false);
                    });
                    
            } else if (handleCalcHours() < 0) {
                if (inProgTimeLog === null) {
                    tempTimeLog = {
                        empUserId: worker,
                        timesheetDate: new Date().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                        hourType: "Regular Work",
                        hoursWorked: (((dayjs(new Date().setHours(0,0,0,0)) - clockInTime) / 3600000) + 24).toFixed(2),
                        tsDescription: "-",
                        tsDay: weekDays[new Date().getDay()].day,
                        weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                    };
                }
                else {
                    tempTimeLog = {
                        timesheetId: inProgTimeLog,
                        empUserId: worker,
                        timesheetDate: new Date().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                        hourType: "Regular Work",
                        hoursWorked: (((dayjs(new Date().setHours(0,0,0,0)) - clockInTime) / 3600000) + 24).toFixed(2),
                        tsDescription: "-",
                        tsDay: weekDays[new Date().getDay()].day,
                        weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                    };
                }
    
                const newTimeLog = tempTimeLog;
    
                axios
                    .put("/api/TimeSheet/updateTimeLog", newTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        fetchTimeSheetData();
                        handleUpdateTimeTable();
                        setTimeSheetListener(timeSheetListener + 1);
                        handleCleanUp();
                        setLoading(false);
                    });

                tempTimeLog = {
                    empUserId: worker,
                    timesheetDate: getNextDateFromDay().toISOString().substring(0, 10),
                    clockIn: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                    clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                    hourType: "Regular Work",
                    hoursWorked: ((clockOutTime - dayjs(new Date().setHours(0,0,0,0))) / 3600000).toFixed(2),
                    tsDescription: "-",
                    tsDay: weekDays[today.getDay()].day === "Saturday" ? "Sunday" : weekDays[today.getDay() + 1].day,
                    weekOf: weekDays[today.getDay()].day !== "Saturday" ? new Date(currentWeek).toISOString().substring(0, 10) : new Date(currentWeek.getFullYear(), currentWeek.getMonth(), currentWeek.getDate()+7).toISOString().substring(0, 10)
                };
    
                const nextTimeLog = tempTimeLog;
    
                axios
                    .put("/api/TimeSheet/updateTimeLog", nextTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        fetchTimeSheetData();
                        handleUpdateTimeTable();
                        setTimeSheetListener(timeSheetListener + 1);
                        handleCleanUp();
                        setDialogTitle("Time sheet saved!");
                        setDialogText("");
                        setOpenDialog(true);
                        setLoading(false);
                    });
            }
            
        }
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setConfirmAction(null);
    };

    const handleConfirmDialog = () => {
        if (confirmAction === 'readyToSubmit') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
                declineNote: null,
            };

            setSubmitForApproval(true);
            setRevokeSubmission(false);
            axios.post("/api/TimeSheet/submitTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error submitting timesheet. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitted(true);
                    setStatus("Pending Approval");
                    fetchWeeklyData();
                });

        } else if (confirmAction === 'revokeSubmission') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
                declineNote: null,
            };

            setApproveSubmission(false);
            setSubmitForApproval(false);
            setRevokeSubmission(true);
            axios.post("/api/TimeSheet/revokeTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error revoking submission. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setApproved(false);
                    setSubmitted(false);
                    setStatus("Not Submitted");
                    fetchWeeklyData();
                });

        } else if (confirmAction === 'readyToApprove') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
                declineNote: null
            };

            setApproveSubmission(true);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            axios.post("/api/TimeSheet/approveTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error approving submission. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setApproved(true);
                    setSubmitted(true);
                    setStatus("Approved");
                    fetchWeeklyData();
                });

        } else if (confirmAction === 'declineSubmission') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
                declineNote: declineReason,
            };

            setApproveSubmission(false);
            setSubmitForApproval(false);
            setRevokeSubmission(true);
            axios.post("/api/TimeSheet/declineTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error declining submission. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setApproved(false);
                    setSubmitted(false);
                    setStatus("Not Submitted");
                    setDeclineReason(null);
                    fetchWeeklyData();
                });

        }
        setOpenDialog(false);
        setConfirmAction(null);
    };

    const handleSubmitForApproval = (event) => {
        if (inProgTimeLog) {
            setDialogTitle("Incomplete Time Log");
            setDialogText("Please clock out of latest clock in entry.");
        } else {
            setDialogTitle("Submit Time Sheet for Approval?");
            setDialogText("Further edits will not be possible unless submission is revoked.");
            setConfirmAction('readyToSubmit');
        }
        setOpenDialog(true);
    };

    const handleRevokeSubmission = (event) => {
        setDialogTitle("Revoke Submission for Approval?");
        setDialogText("Time sheet will be withdrawn from approval process and become editable again.");
        setConfirmAction('revokeSubmission');
        setOpenDialog(true);
    };

    const handleApproval = (event) => {
        setDialogTitle("Approve Timesheet?");
        setDialogText("Further edits will not be possible unless Appoval is revoked.");
        setConfirmAction('readyToApprove');
        setOpenDialog(true);
    };

    const handleDecline = (event) => {
        setDialogTitle("Decline Timesheet?");
        setDialogText("Time sheet will be withdrawn from approval process and become editable again.");
        setConfirmAction('declineSubmission');
        setOpenDialog(true);
    };

    const handleSaveDialog = (event) => {
        setDialogTitle("Time sheet saved!");
        setDialogText("");
        setOpenDialog(true);
    };

    const handlePTOModalOpen = () => {
        setOpenPTOModal(true);
    };

    const handlePTOModalClose = () => {
        setOpenPTOModal(false);
    };

    const handleHolidayModalOpen = () => {
        setOpenHolidayModal(true);
    };

    const handleHolidayModalClose = () => {
        setOpenHolidayModal(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (location.state != null) {
            location.state.tab = newValue;
        }
    };

    return (
        <Grid container style={{ padding: "1%", height: "100%" }}>
            <Grid container direction={"row"}> {/* Weekly Time Sheet */}
                {Authenticated(managerRole) || Authenticated(employeeRole)
                    ? (
                        <Grid item sx={{ width: "20%", paddingRight: "0.5%" }}>
                            <Stack sx={{border: 1.5, borderColor: 'divider', borderRadius: 1, height: 300}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        value={(new Date() >= currentWeek && new Date() <= getWeekRange(new Date(currentWeek)).to) ? dayjs(new Date()) : dayjs(currentWeek)}
                                        onChange={handleDateChange}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            
                            <Stack width="100%" marginY={"10px"}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="worker-label">EMPLOYEE</InputLabel>
                                    <Select
                                        label="EMPLOYEE"
                                        labelId="worker-label"
                                        id="worker-select"
                                        value={worker}
                                        onChange={handleWorkerSelect}
                                    >
                                        {employeeList.map((worker) => (
                                            <MenuItem key={worker.userid} value={worker.userid}>
                                                {`${worker.firstname} ${worker.lastname}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                                <Stack width="48%" >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClockInNow}
                                        disabled={(currentWeek.getTime() !== getWeekRange(today).from.getTime()) || worker === "" || clockInTime || worker !== userId
                                            || status === "Pending Approval" || status === "Approved"
                                        }
                                        style={{ height: '55px' }}
                                    >
                                        Clock In
                                    </Button>
                                </Stack>
                                <Stack width="4%"/>
                                <Stack width="48%">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClockOutNow}
                                        disabled={!clockInTime || loading}
                                        style={{ height: "55px" }}
                                    >
                                        Clock Out
                                    </Button>
                                </Stack>
                            </Stack>

                            <Stack>
                                {Authenticated(employeeRole)
                                    ? (
                                        <>
                                        {submitForApproval
                                            ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                value={revokeSubmission || loading}
                                                onClick={(event) => {
                                                    handleRevokeSubmission(event);
                                                }}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Revoke Submission
                                            </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={submitForApproval || loading}
                                                value={submitForApproval}
                                                onClick={(event) => {
                                                    handleSubmitForApproval(event);
                                                }}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Submit for Approval
                                            </Button>
                                        }
                                        </>
                                    ) : (
                                        null
                                    )}

                                {Authenticated(managerRole)
                                    ? (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={approveSubmission || loading}
                                                value={approveSubmission}
                                                onClick={(event) => {
                                                    handleApproval(event);
                                                }}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Approve
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!submitForApproval}
                                                value={revokeSubmission}
                                                onClick={(event) => {
                                                    handleDecline(event);
                                                }}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Decline
                                            </Button>
                                        </>
                                    ) : (
                                        null
                                    )}
                            </Stack>

                            <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                                <Stack width="44%" >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePTOModalOpen}
                                        style={{ height: '70px' }}
                                    >
                                        Request PTO
                                    </Button>
                                </Stack>
                                <Stack width="4%"/>
                                <Stack width="52%" sx={{borderLeft: "solid grey", borderRight: "solid grey", borderColor: "grey", borderWidth: "1px", paddingX: "5px"}}>
                                    <Typography>PTO Available: {remainingPto + rollOverPto}</Typography>
                                    <Typography>PTO Used: {usedPto}</Typography>
                                    <Typography>PTO Pending: {pendingPto}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    ) : (
                        null
                    )
                }

                {Authenticated(managerRole) || Authenticated(employeeRole)
                    ? (
                        <Grid container sx={{ width: "80%", paddingLeft: "2%", paddingBottom: "2%"}} >
                            <Grid container flexDirection={"row"} sx={{ height: "15%", marginBottom: "2%" }}>
                                <Stack flex={7} direction={"row"} >
                                    {weekDays.map((item, index) => (
                                        <Stack flex={1} justifyContent={"center"} alignItems={"center"} key={index} >
                                            <TimeCell
                                                day={item.day}
                                                type={"Regular Work"}
                                                employeeId={worker}
                                                weekOf={currentWeek}
                                                setTime={handleSetArrayTimes}
                                                updateRow={fetchTimeSheetData}
                                                denyChanges={submitForApproval}
                                                totals={weekTotalHours}
                                                currentTotal={timesArray[weekDays.findIndex(d => d.day === item.day)].hours[5].time}
                                                timeLogsList={timeLogsList}
                                            />
                                            {new Date() >= currentWeek && new Date() <= getWeekRange(new Date(currentWeek)).to && weekdays[today.getDay()] === item.day ? 
                                            (
                                                <Stack
                                                    width={"82%"}
                                                    height={"3%"}
                                                    marginTop={"3%"}
                                                    backgroundColor={"red"}
                                                    borderRadius={"40%"}
                                                />
                                            ) : (null)}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Grid>

                            <Grid container sx={{ width: "100%", paddingRight: "2%"}}>
                                <TimeSheetDetails
                                    employeeId={worker}
                                    weekStart={currentWeek}
                                    weekEnd={getWeekRange(new Date(currentWeek)).to}
                                    status={status}
                                    listener={timeSheetListener}
                                    updateSheet={fetchTimeSheetData}
                                />
                            </Grid>
                        </Grid>
                    ) : (null)
                }

                {Authenticated(payrollRole)
                    ? (
                        <Grid container justifyContents={"flex-start"}>
                            <Grid item sx={{ width: "25%" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(event) => {
                                        handleHolidayModalOpen(event);
                                    }}
                                    style={{ marginBottom: "10px", width: "100%" }}
                                >
                                    Log Holiday Hours
                                </Button>
                                
                                <HolidayTable
                                    holidayListener={holidayListener}
                                    updateHolidayTable={handleUpdateHolidays}
                                />
                            </Grid>
                            
                            <Grid item sx={{ paddingLeft: "1%", }}>
                                <div style={{ ...borderStyle }}></div>
                            </Grid>

                            <Grid item sx={{ width: "73%", paddingLeft: "1%",}}>
                                <PTODetailsTable/> 
                            </Grid>
                        </Grid>
                    ) : (null)
                }
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "1%" }}>
                
                <ContentBoxWrapper sx={{ minHeight: "100%", width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="Time Sheets" {...tabProps(0)} />
                                <Tab label="PTO Requests" {...tabProps(1)} />
                            </Tabs>
                        </div>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0} width={"100%"}>
                        <TimeTable
                            setSheet={handleTimeSheetSelect}
                            currentWeekNumber={currentWeekNumber}
                            year={year}
                            currentUserId={userId}
                            subordinateIdsList={subordinateIdsList}
                            employeeId={worker}
                            submitted={submitted}
                            approved={approved}
                            listener={timeSheetListener}
                            weekTotalHours={weekTotalHours}
                            timeLogsList={timeLogsList}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <PTOReqeustTable
                            currentUserId={userId}
                            subordinateIdsList={subordinateIdsList}
                            listener={ptoRequestListener}
                            updateSheet={fetchTimeSheetData}
                        />
                    </CustomTabPanel>
                </ContentBoxWrapper>
            </Grid>

            <PTORequestForm
                openPTOModal={openPTOModal}
                handlePTOModalClose={handlePTOModalClose}
                updatePtoRequests={handleUpdatePtoRequests}
                updatePtoDisplay={fetchEmployeeData}
            />

            <HolidayInputForm
                openHolidayModal={openHolidayModal}
                handleHolidayModalClose={handleHolidayModalClose}
                updateHolidayTable={handleUpdateHolidays}
            />

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogText}
                </DialogContent>
                {dialogTitle === "Decline Timesheet?" ? (
                    <Stack style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                        <TextField
                            label="Reason for Decline"
                            onChange={(event) => setDeclineReason(event.target.value)}
                        />
                    </Stack>
                ) : (
                    null
                )}
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                    {dialogTitle === "Time sheet saved!" || dialogTitle === "Incomplete Time Log" ? (
                        null
                    ) : (
                        <Button onClick={handleConfirmDialog} color="primary">
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Time sheet saved!
                </MuiAlert>
            </Snackbar>
        </Grid>
    );
}
